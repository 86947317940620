<template>
  <b-modal id="supplier-merge-modal" size="lg" title="Merge Supplier" v-model="showModal" v-if="currentSupplier">
    <b-overlay :show="loading" rounded="sm">
      <p>This tool will merge all artefacts from another supplier into {{currentSupplier.name}}. All prices from the supplier you select below will be moved to {{currentSupplier.name}} and the selected supplier will be deleted. </p>
      <b-alert variant="danger" :show="this.errors.length > 0">
        <ul>
          <li v-for="error in this.errors" :key="error">{{ error }}</li>
        </ul>
      </b-alert>

      <div class="formRow">
        <div class="formElement">
          <div class="formElement__label">
            <label>Select Supplier <span class="text-danger">*</span></label>
          </div>
          <div class="formElement__input">
            <v-select name="supplier-select" id="supplier-select" taggable :placeholder="`Please select`" class="wide"
                      v-model="chosenSupplier" label="name"
                      :options="suppliers"></v-select>
          </div>
        </div>
      </div>

      <div class="formRow">
        <div class="formElement">
          <div class="formElement__label">
            <label for="ean-case">Overwrite settings?</label>
          </div>
          <div class="formElement__input">
            <b-form-checkbox
                v-model="shouldOverwriteSettings"
                class="form-check mx-1"
                :value="true"
                :unchecked-value="false"
            >
              Should the price and expiry settings of {{ currentSupplier.name }} be overwritten by the selected supplier?
            </b-form-checkbox>
          </div>
        </div>
      </div>
    </b-overlay>
    <template #modal-footer>

      <b-button variant="success" @click="merge">
        Merge
      </b-button>
      <b-button variant="light" @click="reset">
        Cancel
      </b-button>
    </template>

  </b-modal>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: "SupplierMergeModal",
  data() {
    return {
      chosenSupplier: null,
      errors: [],
      shouldOverwriteSettings: false
    }
  },
  components: {},
  computed: {
    showModal: {
      get() {
        return this.$store.state.suppliers.modals.isMergeModalOpen
      },
      set(v) {
        if (v) {
          this.$store.dispatch('suppliers/closeSupplierMergeModal');
        } else {
          this.$store.dispatch('suppliers/openSupplierMergeModal');
        }
      }
    },
    ...mapState({
      currentSupplier: state => state.suppliers.currentSupplier,
      loading: state => state.suppliers.isFetching,
      suppliers: state => state.suppliers.suppliers
    })
  },
  methods: {
    merge() {
      this.$store.dispatch('suppliers/mergeSuppliers', {
        supplier1: this.currentSupplier.id,
        supplier2: this.chosenSupplier.id,
        shouldOverwriteSettings: this.shouldOverwriteSettings
      });
    },
    reset() {
      this.$store.dispatch('suppliers/closeSupplierMergeModal');
    }
  }

}
</script>
