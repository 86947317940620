<template>
  <b-modal id="price-list-modal" size="lg" title="Create/Edit Supplier" v-model="this.showModal" v-if="currentCurrency">
    <b-overlay :show="loading" rounded="sm">
      <p>Please enter the Exchange rate for this currency against 1 EUR</p>
      <b-alert variant="danger" :show="this.errors.length > 0">
        <ul>
          <li v-for="error in this.errors" :key="error">{{ error }}</li>
        </ul>
      </b-alert>

      <div class="formRow">
        <div class="formElement">
          <div class="formElement__label">
            <label>1 EUR equals </label>
          </div>
          <div class="formElement__input">
            <b-form-input v-model="currentCurrency.exchange_rate" placeholder="Please enter"></b-form-input>
            <label style="margin: 0 5px;">{{ currentCurrency.code }}</label>
          </div>
        </div>
      </div>
      <div class="formRow">
        <div class="formElement">
          <b-form-checkbox
              v-model="currentCurrency.ignore_from_update"
              class="form-check"
              id="customControlInline"
              name="checkbox-1"
              value="1"
              unchecked-value="0"
          >
            Disable automatic updates
          </b-form-checkbox>
        </div>
      </div>

    </b-overlay>
    <template #modal-footer>

      <b-button variant="success" @click="save">
        Save
      </b-button>
      <b-button variant="light" @click="reset">
        Cancel
      </b-button>
    </template>

  </b-modal>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: "CurrencyModal",
  data() {
    return {
      errors: []
    }
  },
  components: {},
  computed: {
    showModal: {
      get() {
        return this.$store.state.currencies.modals.isCurrencyModalOpen
      },
      set(v) {
        if (v) {
          this.$store.dispatch('currencies/closeCurrencyModal');
        } else {
          this.$store.dispatch('currencies/openCurrencyModal');
        }
      }
    },
    ...mapState({
      currentCurrency: state => state.currencies.selectedCurrency,
      loading: state => state.currencies.isFetching,
    })
  },
  methods: {
    save() {
      if (this.validate() !== true) {
        this.$toast.error('Please add an Exchange rate.');
        return false;
      }

      this.$store.dispatch('currencies/updateCurrency', this.currentCurrency).then(() => {
        this.reset();
        this.$store.dispatch('currencies/fetchCurrencies')
      }, (error) => {
        this.$toast('Something went wrong! ' + error.toString());
      });
    },
    validate() {
      if (!this.currentCurrency.exchange_rate) {
        return false;
      }

      return true;

    },
    reset() {
      this.$store.dispatch('currencies/closeCurrencyModal');
    }
  }

}
</script>
