<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="mb-0 font-size-18">Currencies</h4>
        </div>
      </div>
      <div class="col-12">
        <CurrenciesGrid/>
        <CurrencyModal />
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '../layouts/horizontal.vue';
import CurrenciesGrid from "../components/currencies/CurrenciesGrid";
import CurrencyModal from "../components/currencies/CurrencyModal";

export default {
  name: 'Currencies',
  components: {
    CurrenciesGrid,
    CurrencyModal,
    Layout
  },
  methods: {
    openModal() {
      this.$store.dispatch('suppliers/openCurrencyModal');
    }
  },
}
</script>
