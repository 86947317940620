<template>
  <b-modal id="supplier-modal" size="lg" :title="currentSupplier ? currentSupplier.name : 'Create/Edit Supplier'"
           v-model="showModal" v-if="currentSupplier">

    <b-tabs card>
      <b-tab title="Basic Details" active>
        <b-overlay :show="loading" rounded="sm">
          <p>Please fill in basic details for this Supplier and press Save</p>
          <b-alert variant="danger" :show="this.errors.length > 0">
            <ul>
              <li v-for="error in this.errors" :key="error">{{ error }}</li>
            </ul>
          </b-alert>

          <div class="formRow">
            <div class="formElement">
              <div class="formElement__label">
                <label>Name</label>
              </div>
              <div class="formElement__input flex-grow-1">
                <b-form-input v-model="currentSupplier.name" placeholder="Please enter" class="full-width" :disabled="role !== 'admin'"></b-form-input>
              </div>
            </div>
          </div>

          <div class="formRow">
            <div class="formElement">
              <div class="formElement__label">
                <label>Category</label>
              </div>
              <div class="formElement__input">
                <v-select name="category-select" id="category-select" :placeholder="`Please select`" :disabled="role !== 'admin'"
                          v-model="currentSupplier.category"
                          :options="[
                          'A',
                          'B',
                          'C'
                      ]">

                </v-select>
              </div>
            </div>
          </div>

         <div class="formRow">
            <div class="formElement">
              <div class="formElement__label">
                <label for="honors_paper_trail_select">Honors Paper Trail?
                </label>
              </div>
              <div>
                <div class="formElement__input">
                  <v-select name="honors-paper-trail-select" id="honors_paper_trail_select" :placeholder="`Please select`"
                            :disabled="role !== 'admin'"
                            class="wide"
                            v-model="currentSupplier.honors_paper_trail"
                            :reduce="option => option.value"
                            :options="[{
                            label: 'Yes',
                            value: 1
                          },
                          {
                            label: 'No',
                            value: 0
                          }]"></v-select>
                </div>
              </div>
            </div>
          </div>

          <div class="formRow">
            <div class="formElement">
              <div class="formElement__label">
                <label for="price-expiry">Default Product Type
                  <PopoverHelper text="T1 Products don't require Paper Trail; T2 Products require Paper Trail" />
                </label>
              </div>
              <div>
                <div class="formElement__input">
                  <v-select name="honors-paper-trail-select" id="honors_paper_trail_select" :placeholder="`Please select`"
                            class="wide"
                            :disabled="role !== 'admin'"
                            v-model="currentSupplier.default_product_type"
                            :reduce="option => option.value"
                            :options="[
                          {
                            label: 'Please Select',
                            value: null
                          },
                          {
                            label: 'T1',
                            value: 'T1'
                          },
                          {
                            label: 'T2',
                            value: 'T2'
                          }]"></v-select>
                </div>
              </div>
            </div>
          </div>

          <div class="formRow">
            <div class="formElement">
              <div class="formElement__label">
                <label for="price-expiry">Contact Email
                  <PopoverHelper text="This email will receive alert notifications as pricelists expire" />
                </label>
              </div>
              <div>
                <div class="formElement__input">
                  <b-form-input v-model="currentSupplier.contact_email" :disabled="role !== 'admin'"
                                placeholder="Please enter" class="full-width"></b-form-input>
                </div>
              </div>
            </div>
          </div>

        </b-overlay>
      </b-tab>
      <b-tab title="Expiry Settings">

        <b-overlay :show="loading" rounded="sm">
          <p> In relation to sale price calculation, different price lists may have different settings for expiry of prices and quantities. </p>
          <div class="formRow">
            <div class="formElement">
              <div class="formElement__label">
                <label for="price-expiry">Benchmark Expiry
                  <PopoverHelper text="This is a universal setting for all Pricelist types as it relates to Benchmark pricing.
                   Pricelists that are older than this setting will not factor into the determination of benchmark pricing" />
                </label>
              </div>
              <div>
                <div class="formElement__input">
                  <v-select name="expiry-select" id="expiry-select" :placeholder="`Please select`"
                            :disabled="role !== 'admin'"
                            v-model="currentSupplier.expiry_benchmark_prices"
                            :options="['1 week', '2 weeks', '1 month', '2 months', '3 months', '4 months', '5 months', '6 months',
                      '7 months', '8 months', '9 months', '10 months', '11 months', '12 months']">

                  </v-select>
                </div>
              </div>
            </div>
          </div>

          <p class="mb-0 mt-4"><strong>Monthly/Weekly Pricelists</strong></p>
          <div class="formRow">
            <div class="formElement">
              <div class="formElement__label">
                <label for="price-expiry">Price Expiry
                  <PopoverHelper text="This is the default length of time before monthly/weekly price lists for this supplier expire. This can be overridden for individual Pricelists" />
                </label>
              </div>
              <div>
                <div class="formElement__input">
                  <v-select name="expiry-select" id="expiry-select" :placeholder="`Please select`"
                            :disabled="role !== 'admin'"
                            v-model="currentSupplier.expiry_regular_pricelists"
                            :options="['1 week', '2 weeks', '1 month', '2 months', '3 months', '4 months', '5 months', '6 months',
                      '7 months', '8 months', '9 months', '10 months', '11 months', '12 months']">

                  </v-select>
                </div>
              </div>
            </div>
          </div>

          <div class="formRow">
            <div class="formElement">
              <div class="formElement__label">
                <label for="price-expiry">Quantity Expiry
                  <PopoverHelper text="This is the default length of time before monthly/weekly price lists for this supplier expire. This can be overridden for individual Pricelists" />
                </label>
              </div>
              <div>
                <div class="formElement__input">
                  <v-select name="expiry-select" id="expiry-select" :placeholder="`Please select`"
                            :disabled="role !== 'admin'"
                            v-model="currentSupplier.expiry_quantity_regular_pricelists"
                            :options="['1 week', '2 weeks', '1 month', '2 months', '3 months', '4 months', '5 months', '6 months',
                      '7 months', '8 months', '9 months', '10 months', '11 months', '12 months']">

                  </v-select>
                </div>
              </div>
            </div>
          </div>

          <p class="mb-0 mt-4"><strong>Spot Pricelists</strong></p>

          <div class="formRow">
            <div class="formElement">
              <div class="formElement__label">
                <label for="price-expiry">Price Expiry
                  <PopoverHelper text="This is the default length of time before Spot price lists for this supplier expire. This can be overridden for individual Pricelists" />
                </label>
              </div>
              <div>
                <div class="formElement__input">
                  <v-select name="expiry-select" id="expiry-select" :placeholder="`Please select`"
                            :disabled="role !== 'admin'"
                            v-model="currentSupplier.expiry_spot_pricelists"
                            :options="['1 week', '2 weeks', '1 month', '2 months', '3 months', '4 months', '5 months', '6 months',
                      '7 months', '8 months', '9 months', '10 months', '11 months', '12 months']">

                  </v-select>
                </div>
              </div>
            </div>
          </div>

          <div class="formRow">
            <div class="formElement">
              <div class="formElement__label">
                <label for="price-expiry">Quantity Expiry
                  <PopoverHelper text="This is the default length of time before monthly/weekly price lists for this supplier expire. This can be overridden for individual Pricelists" />
                </label>
              </div>
              <div>
                <div class="formElement__input">
                  <v-select name="expiry-select" id="expiry-select" :placeholder="`Please select`"
                            :disabled="role !== 'admin'"
                            v-model="currentSupplier.expiry_quantity_spot_pricelists"
                            :options="['1 week', '2 weeks', '1 month', '2 months', '3 months', '4 months', '5 months', '6 months',
                      '7 months', '8 months', '9 months', '10 months', '11 months', '12 months']">

                  </v-select>
                </div>
              </div>
            </div>
          </div>

          <p class="mb-0 mt-4"><strong>Annual Pricelists</strong></p>

          <div class="formRow">
            <div class="formElement">
              <div class="formElement__label">
                <label for="price-expiry">
                  Price Expiry
                  <PopoverHelper text="This is the default length of time before Annual price lists for this supplier expire. This can be overridden for individual Pricelists" />
                </label>
              </div>
              <div>
                <div class="formElement__input">
                  <v-select name="expiry-select" id="expiry-select" :placeholder="`Please select`"
                            :disabled="role !== 'admin'"
                            v-model="currentSupplier.expiry_annual_pricelists"
                            :options="['1 week', '2 weeks', '1 month', '2 months', '3 months', '4 months', '5 months', '6 months',
                      '7 months', '8 months', '9 months', '10 months', '11 months', '12 months']">

                  </v-select>
                </div>
              </div>
            </div>
          </div>

          <div class="formRow">
            <div class="formElement">
              <div class="formElement__label">
                <label for="price-expiry">Quantity Expiry
                  <PopoverHelper text="This is the default length of time before monthly/weekly price lists for this supplier expire. This can be overridden for individual Pricelists" />
                </label>
              </div>
              <div>
                <div class="formElement__input">
                  <v-select name="expiry-select" id="expiry-select" :placeholder="`Please select`"
                            :disabled="role !== 'admin'"
                            v-model="currentSupplier.expiry_quantity_annual_pricelists"
                            :options="['1 week', '2 weeks', '1 month', '2 months', '3 months', '4 months', '5 months', '6 months',
                      '7 months', '8 months', '9 months', '10 months', '11 months', '12 months']">

                  </v-select>
                </div>
              </div>
            </div>
          </div>

        </b-overlay>

      </b-tab>
      <b-tab title="Markup Settings">
        <p>These settings control the markups applied to supplier prices to determine Sale prices.</p>
        <div class="formRow">
          <div class="formElement">
            <div class="formElement__input" style="flex-grow: 1">
              <table class="tiersTable">
                <thead>
                <tr>
                  <th >Price From</th>
                  <th>Price To</th>
                  <th>Markup Amount</th>
                  <th>Markup Percentage</th>
                  <th>
                    Stock Tolerance Percentage
                    <PopoverHelper text="If the system cannot establish a reliable stock level from the lowest available supplier
                    price, it will look for prices within this range (+/-) for quantity." />
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(tier, index) in tiers" :key="index">
                  <td>
                    <div>
                      €
                      <b-form-input v-model="tier.price_limit_lower" type="number" disabled class="condensed-input"
                                    placeholder="Please enter"></b-form-input>
                    </div>
                  </td>
                  <td>
                    <div>
                      €
                      <b-form-input v-model="tier.price_limit_upper" type="number" disabled class="condensed-input" placeholder="Infinity"></b-form-input>
                    </div>
                  </td>
                  <td>
                    <div>
                      €
                      <b-form-input v-model="tier.markup_amount" type="number" class="condensed-input" :disabled="role !== 'admin'" placeholder="Please enter" step="0.01"></b-form-input>
                    </div>
                  </td>
                  <td>
                    <div>
                      <b-form-input v-model="tier.markup_percentage" type="number" step="0.01" class="condensed-input" :disabled="role !== 'admin'"
                                    placeholder="Please enter"></b-form-input>
                      %
                    </div>
                  </td>
                  <td >
                    <div>
                      <b-form-input v-model="tier.stock_tolerance_percentage" type="number" step="0.01" class="condensed-input" :disabled="role !== 'admin'"
                                    placeholder="Please enter"></b-form-input>
                      %
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </b-tab>
    </b-tabs>

    <template #modal-footer>
      <b-button variant="info" @click="saveAndNext">
        Save & Next
      </b-button>
      <b-button variant="success" @click="save">
        Save
      </b-button>
      <b-button variant="light" @click="reset">
        Cancel
      </b-button>
    </template>

  </b-modal>
</template>

<script>

import {mapState} from "vuex";
import PopoverHelper from "../common/PopoverHelper.vue";

export default {
  name: "SupplierModal",
  data() {
    return {
      errors: [],
      role: ''
    }
  },
  components: {PopoverHelper},
  computed: {
    tiers: {
      get() {
        return this.currentSupplier.tiers;
      },
      set(v) {
        this.currentSupplier.tiers = v;
      }
    },
    showModal: {
      get() {
        return this.$store.state.suppliers.modals.isSupplierModalOpen
      },
      set(v) {
        if (v) {
          this.$store.dispatch('suppliers/openSupplierModal');
        } else {
          this.$store.dispatch('suppliers/closeSupplierModal');
        }
      }
    },
    ...mapState({
      currentSupplier: state => state.suppliers.currentSupplier,
      loading: state => state.suppliers.isFetching,
    })
  },
  methods: {
    save() {
      this.$store.dispatch('suppliers/updateSupplier', {
        ...this.currentSupplier,
        tiers: this.tiers
      });
    },
    saveAndNext() {
      this.$store.dispatch('suppliers/updateSupplierAndOpenNext', {
        ...this.currentSupplier,
        tiers: this.tiers,
      })
    },
    reset() {
      this.$store.dispatch('suppliers/closeSupplierModal');
    }
  },
  created() {
    const userObject = JSON.parse(localStorage.getItem('latina-sam-user'));
    this.role = userObject.role;
  }

}
</script>

<style lang="scss">

.condensed-input {
  padding: 0.2rem 0.5rem;
}

.formRow {
  .formElement__label {
    min-width: 280px;
  }
}

.tiersTable {
  width: 100%;
  border-collapse: collapse;

  th {
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    padding: 8px;
    text-align: left;
  }

  td {
    border: 1px solid #dee2e6;
    padding: 3px 8px;
    width: 25%;

    div {
      display: flex;
      align-items: center;
    }
  }

  input {
    width: 100px;
    border: 0;
    margin-left: 5px;
  }

  tr:nth-child(even) {
    background-color: #f8f9fa;
  }
}

button.helpTip {
  padding: 0.3rem;
  background: #eee;
  color: #333;
  border: 0;
}

.vs__selected-options {
  min-width: 160px;
  flex-wrap: nowrap !important;
}

</style>