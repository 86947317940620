<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="mb-0 font-size-18">Suppliers</h4>

          <div class="page-title-right">
            <span class="rowCount">
              <strong>A</strong>: Use all kinds of prices
              <strong>B</strong>: Only use Pricelist and no stock,
              <strong>C</strong>: Sale Prices not calculated</span>
            <ol class="breadcrumb m-0">
              <li class="breadcrumb-item active">
                <b-dropdown text="Actions" variant="primary">
                  <b-dropdown-item @click="openModal">Create Supplier</b-dropdown-item>
                </b-dropdown>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div class="col-12">
        <SuppliersGrid/>
      </div>
    </div>
    <SupplierModal />
    <SupplierMergeModal />
  </Layout>
</template>

<script>
import Layout from '../layouts/horizontal.vue';
import SuppliersGrid from "../components/suppliers/SuppliersGrid";
import SupplierModal from "../components/suppliers/SupplierModal";
import SupplierMergeModal from "../components/suppliers/SupplierMergeModal";

export default {
  name: 'Suppliers',
  components: {
    SupplierModal,
    SuppliersGrid,
    SupplierMergeModal,
    Layout
  },
  methods: {
    openModal() {
      this.$store.dispatch('suppliers/openSupplierModal');
    }
  },
}
</script>
