<template>
  <b-button class="helpTip" size="sm" variant="info"
            v-b-popover.hover.top="text">
    <i class="bx bx-question-mark"></i>
  </b-button>
</template>

<script lang="ts">

export default {

  name: "PopoverHelper",
  props: {
    text: String
  }
}
</script>

<style lang="scss">
button.helpTip {
  padding: 0.3rem;
  background: #eee;
  color: #333;
  border: 0;
}
</style>